<template>
  <UserSingleCard showLogo>
    <template v-slot:title>
      <p class="logout-title">ログアウト</p>
    </template>

    <template v-slot:button>
      <WideButton
        class="logout-button"
        :label="'ログアウト'"
        @click="$_logOut"
      />
      <WideButton
        class="cancel-button"
        :label="'キャンセル'"
        :backgroundColor="'#707070'"
        @click="() => $router.push('/')"
      />
    </template>
  </UserSingleCard>
</template>

<script>
import WideButton from '@/components/atoms/common/AppWideButton'
import UserSingleCard from '@/templates/user/AppUserSingleCard'
import { logOutApp } from '@/helper/auth'
import { showInfoPopupAlert, showConfirmPopup } from '@/helper/common'

/**
 * FIXME: pageに具体的なコンポーネントの配置を記述するのではなく、templateに移すこと
 */
export default {
  name: 'TheLogOut',
  components: {
    UserSingleCard,
    WideButton
  },
  methods: {
    $_logOut: async function () {
      try {
        const result = await showConfirmPopup(
          'ログアウトしますか？',
          'はい',
          `いいえ`
        )

        this.$store.dispatch('loadingMask/showLoadingMask')

        if (result.isConfirmed) {
          await logOutApp()
          this.$router.push('/')
        }
      } catch (error) {
        showInfoPopupAlert(`ログアウトに失敗しました。（${error.message}`)
      } finally {
        this.$store.dispatch('loadingMask/hideLoadingMask')
      }
    }
  },
}
</script>

<style lang="scss">
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid gray;
  border-radius: 4px;
}
.logout-title {
  margin: 16px 0;
}
.logout-email-title {
  text-align: left;
  margin-bottom: 8px;
  font-size: 12px;
}
.email {
  margin: 0;
}
.logout-password-title {
  text-align: left;
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
}
.password {
  margin: 0;
}
.logout-button {
  margin-top: 32px;
}
.cancel-button {
  margin-top: 0px;
}
.forgot-password {
  color: #707070;
  text-decoration: underline;
  cursor: pointer;
}
</style>
